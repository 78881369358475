import axios from "axios";
import get from "lodash/get";
const {
  SLOT_MACHINE,
  FROLIC_GAMES,
} = require("../../../app/common/event_names.js");

class Frolic {
  constructor() {
    this.widgets = {};
    this.pageModels = [];
    this.conf = {};
    this.currentPage = "";
    this.slotUrl = "";
    this.widgetSlug = [];
    this.widgetId = "";
    this.url = "";
    this.campaigns = {};
    this.user = {};
  }

  getQueryParam(key) {
    const queryString = window.location.search;
    const params = new URLSearchParams(queryString);
    return params.get(key);
  }

  getBaseUrl() {
    return window.location.href.split("/")[2];
  }

  async fetchUserExist() {
    const apiUrl = `/ext/fynd-engage/application/engage/v1.0/user/exist`;
    try {
      const response = await axios.get(apiUrl);
      return response.data;
    } catch (error) {
      console.error("Error fetching user existence data:", error);
      return null;
    }
  }

  async fetchCampaigns() {
    const currentPageUrl = this.getBaseUrl();
    const apiUrl = `https://${currentPageUrl}/ext/fynd-engage/application/engage/v1.0/game/campaign`;
    try {
      const response = await axios.get(apiUrl);
      return response.data;
    } catch (error) {
      console.error("Error fetching campaigns:", error);
      return null;
    }
  }

  async init(eventName, callback) {
    const that = this;

    this.currentPage = window.FPI.utility.convertUrlToAction(
      window.location.href
    ).page.type;

    // First call userExist API
    const userExistData = await this.fetchUserExist();
    if (!userExistData || !userExistData.userExist) {
      console.log("User does not exist.");
      return;
    }
    // If user exists, call the campaigns API
    const campaignsData = await this.fetchCampaigns();
    if (!campaignsData) {
      console.error("Failed to fetch campaigns data");
      return;
    }
    this.conf = campaignsData;
    this.user = this.conf.user;

    const filteredCampaigns = this.conf.campaigns.filter(
      (campaign) => campaign.type === eventName
    );

    const campaign = filteredCampaigns[0];
    this.conf.campaign = campaign;

    window.FPI.extension.register(`#fynd-engage-games`, {
      mounted() {
        if (window.FPI) {
          window.FPI.state.user.subscribe(() => {
            that.widgetSlug.push(campaign.type);
            that.loadWidget(campaign, callback);
          });
        }
      },

      destroyed() {
        if (window.FPI) {
          window.FPI.state.user.subscribe(() => {
            that.widgetSlug.forEach((slug) => {
              that.widgets[slug].state = "closed";
            });
            that.hideWidgets();
          });
        }
      },
    });
  }

  initAnotherWidget(eventName, callback) {
    if (eventName === SLOT_MACHINE) {
      const filteredCampaigns = this.conf.campaigns.filter(
        (campaign) => campaign.type === FROLIC_GAMES
      );

      const campaign = filteredCampaigns[0];
      this.conf.campaign = campaign;

      this.loadWidget(campaign, callback);
    } else if (eventName === FROLIC_GAMES) {
      const filteredCampaigns = this.conf.campaigns.filter(
        (campaign) => campaign.type === FROLIC_GAMES
      );

      const campaign = filteredCampaigns[0];
      this.conf.campaign = campaign;
    }
  }

  loadActiveWidgets(currentPageUrl) {
    this.hideWidgets();
    this.currentPage = window.FPI.utility.convertUrlToAction(
      currentPageUrl ?? window.location.href
    ).page.type;
    this.slotUrl = currentPageUrl ?? window.location.href;
    const widgetsOnCurrentPage = this.campaigns.filter((widget) =>
      widget.distributionChannel.page.includes(this.currentPage)
    );
    widgetsOnCurrentPage.forEach((currentWidget) => {
      this.loadWidget(currentWidget);
    });
  }

  hideWidgets() {
    for (let [innerObj] of Object.entries(this.widgets)) {
      innerObj.logohide();
    }
  }

  getWidgetConfig(slug) {
    if (this.conf.campaign.type === slug) {
      return this.conf.campaign;
    }
  }

  registerWidget(slug, widget) {
    this.widgets[slug] = widget;
  }

  injectPopUiScript(widgetConfig, callback) {
    const script = document.createElement("script");
    script.src = `${this.conf.base_url}/popup/${widgetConfig.type}/${widgetConfig.type}.umd.js`;
    script.id = `frolic-popup-${widgetConfig.type}`;
    document.body.append(script);
    script.addEventListener("load", () => {
      const widget = get(this, `widgets.${widgetConfig.type}`);
      typeof callback === "function" ? callback(widget) : "";
    });
    script.addEventListener("error", () => {
      console.log("Error in loading popup script");
    });
  }

  onSlotLoaded() {
    const that = this;
    return (slotObj) => {
      if (
        that.getQueryParam("f") == slotObj.widgetConfig.id ||
        that.getQueryParam("c")
      ) {
        var isBanner = true;
        slotObj.logoClickHandler(isBanner);
      }
    };
  }

  setWidgetLoading(slug) {
    if (!this.widgets[slug]) {
      this.widgets[slug] = {
        loading: true,
      };
    }
  }

  isWidgetLoading(slug) {
    return get(this.widgets, `${slug}.loading`, false);
  }

  loadWidget(currentWidget, callback) {
    const that = this;
    const isWidgetLoading = this.isWidgetLoading(currentWidget.type);
    if (!isWidgetLoading && this.widgets[currentWidget.type]) {
      this.widgets[currentWidget.type].init(this.onSlotLoaded());
      if (callback) callback();
    } else if (!isWidgetLoading) {
      that.setWidgetLoading(currentWidget.type);
      this.injectPopUiScript(currentWidget, (widget) => {
        if (widget) {
          widget?.init(this.onSlotLoaded());
          if (callback) callback();
        }
      });
    }
  }
}

const frolicObject = new Frolic();

window.addEventListener("load", function () {
  (function (window) {
    window.__frolic_engage = frolicObject;
    window.__frolic_engage.init(SLOT_MACHINE, () => {
      window.__frolic_engage.initAnotherWidget(SLOT_MACHINE, () => {
        console.log("All events triggered.");
      });
    });
    console.log("Slot Machine Event Triggered");
  })(window);
});
